import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LV1_HEALTH_FACTORS, Lv1HealthFactor } from "@/constants";
import { DateRange, PaginationInfo, PolarityInfo } from "@/model";
import { Article, HealthScore, Prediction } from "@/model/api";
import {
  CompanyProfileInitialState,
  SelectedIssue,
  SelectedPeakGraphData,
} from "@/model/companyProfile";
import { LiteCompany, PremiumCompany } from "@/model/general";
import { getPastDateString } from "@/util/dateCalculations";

export const initialState: CompanyProfileInitialState = {
  company: {
    Id: "",
    IsDelisted: false,
    IsPennyStock: false,
    Name: "",
    Sector: "",
    Ticker: "",
  },
  healthFactors: [],
  lite_company: {
    id: "",
    is_bookmarked: false,
    is_public: false,
    name: "",
    sector: "",
    ticker: "",
  },
  news: {
    articles: [],
    date: {
      MaxDate: getPastDateString(),
      MinDate: getPastDateString(180),
    },
    healthFactorsFilter: LV1_HEALTH_FACTORS,
    pagination: {
      PageCount: 0,
      PageIndex: 1,
    },
  },
  predictionData: [],
  selectedHFLegend: LV1_HEALTH_FACTORS,
  selectedIssue: {
    IsIssueFromOverview: false,
    articlesCount: 0,
    companyId: null,
    issueName: null,
    issuePeaks: null,
    maxDate: getPastDateString(),
    minDate: getPastDateString(7),
    polarity: null,
    sentenceCount: 0,
  },
  selectedIssueData: {
    Name: "",
    Polarity: "",
  },
  selectedTopicDateData: [],
  showTopicsToggle: true,
};

export const CompanyProfileSlice = createSlice({
  initialState,
  name: "companyProfileSlice",
  reducers: {
    SetArticles: (state, action: PayloadAction<Article[]>) => {
      state.news.articles = action.payload;
    },
    SetCompany: (state, action: PayloadAction<PremiumCompany>) => {
      state.company = { ...state.company, ...action.payload };
    },
    SetHealthFactors: (state, action: PayloadAction<HealthScore[]>) => {
      state.healthFactors = action.payload;
    },
    SetHealthFactorsFilter: (
      state,
      action: PayloadAction<Lv1HealthFactor[]>
    ) => {
      state.news.healthFactorsFilter = action.payload;
    },
    SetLiteCompany: (state, action: PayloadAction<LiteCompany>) => {
      state.lite_company = { ...state.lite_company, ...action.payload };
    },
    SetNewsParameters: (state, action: PayloadAction<DateRange>) => {
      state.news.date = { ...state.news.date, ...action.payload };
    },
    SetPagination: (state, action: PayloadAction<PaginationInfo>) => {
      state.news.pagination = { ...state.news.pagination, ...action.payload };
    },
    SetPredictionData: (state, action: PayloadAction<Prediction[]>) => {
      state.predictionData = action.payload;
    },
    SetSelectedHFLegend: (state, action: PayloadAction<Lv1HealthFactor[]>) => {
      state.selectedHFLegend = action.payload;
    },
    SetSelectedIssue: (state, action: PayloadAction<SelectedIssue>) => {
      state.selectedIssue = action.payload;
    },
    SetSelectedIssueData: (state, action: PayloadAction<PolarityInfo>) => {
      state.selectedIssueData = action.payload;
    },
    SetSelectedTopicDateData: (
      state,
      action: PayloadAction<SelectedPeakGraphData[]>
    ) => {
      state.selectedTopicDateData = action.payload;
    },
    SetshowTopicsToggle: (state, action: PayloadAction<boolean>) => {
      state.showTopicsToggle = action.payload;
    },
  },
});

export const {
  SetCompany,
  SetLiteCompany,
  SetPredictionData,
  SetHealthFactors,
  SetArticles,
  SetNewsParameters,
  SetPagination,
  SetHealthFactorsFilter,
  SetSelectedIssue,
  SetSelectedTopicDateData,
  SetshowTopicsToggle,
  SetSelectedIssueData,
  SetSelectedHFLegend,
} = CompanyProfileSlice.actions;
export default CompanyProfileSlice.reducer;
